/* eslint-disable max-len */
import React from 'react'

export const metal3Logo = <Metal3Logo />

export function Metal3Logo({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 330 319"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Metal³ Logo</title>
      <g id="Metal³Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Metal³LogoMask" transform="translate(0.853764, 0.480000)" fillRule="nonzero">
          <path
            d="M27.1558907,79.9883562 L164.100011,155.192906 L164.211236,155.387891 L164.601236,155.708934 L163.78626,316.658943 L163.536236,316.86 L101.136236,317.7 C100.716236,317.67 100.296236,317.56 99.8762358,317.56 C93.8319388,316.81941 88.2044012,314.093571 83.8762358,309.81 L83.8762358,309.81 L52.1462358,270.52 L19.6062358,230.27 L4.48623577,211.6 C3.38869558,210.04448 2.47589033,208.366529 1.76623577,206.6 C1.36279513,205.641842 1.02860423,204.655979 0.766235766,203.65 C0.676235766,203.31 0.586235766,202.96 0.516235766,202.6 C0.225972133,201.257748 0.0553037587,199.892401 0.00623576649,198.52 L0.00623576649,198.52 L0.00623576649,198.38 C-0.0302830352,196.778311 0.0936685888,195.17699 0.376235766,193.6 L0.376235766,193.6 L9.77623577,152.37 L26.2162358,80.23 L27.1558907,79.9883562 Z M60.9081305,110.388257 C60.4093999,110.684634 60.1015812,111.219877 60.0962358,111.8 L59.5062358,215.33 C59.6389868,217.904336 60.9574968,220.271745 63.0762358,221.74 L149.976236,269.9 C150.845427,270.38299 151.905571,270.368761 152.761486,269.862619 C153.617402,269.356476 154.14067,268.43436 154.136264,267.44 L154.136264,165.23 C154.133534,161.602429 152.135182,158.270561 148.936236,156.56 L147.936236,156.01 L62.5362358,110.35 C62.0241347,110.077376 61.4068611,110.091881 60.9081305,110.388257 Z"
            id="Metal³LogoLeftSquare"
            fill="#00EEC4"
          />
          <path
            d="M302.024243,82.8866021 L302.991236,83.2994577 L328.086236,193.42 C328.860566,199.761348 327.237809,206.163213 323.536236,211.37 L323.536236,211.37 L306.436236,232.52 L286.566236,257.09 L244.636236,308.96 C242.200452,312.278337 238.727009,314.688984 234.766236,315.81 C234.376236,315.92 233.986236,316.03 233.596236,316.12 C231.581017,316.594023 229.516395,316.825663 227.446236,316.81 L227.446236,316.81 L163.536236,316.858851 L164.211236,155.387891 L164.601236,154.681127 L302.024243,82.8866021 Z M264.766236,110.18 L249.216236,118.78 C246.907386,120.031593 245.130865,122.078146 244.216236,124.54 L220.446236,180.09 L196.766236,150.78 C195.759569,149.32 194.092902,149.223333 191.766236,150.49 L176.216236,159.09 C175.171785,159.672186 174.293882,160.512219 173.666236,161.53 C173.036609,162.446896 172.688997,163.52797 172.666236,164.64 L172.666236,270.52 C172.539256,271.307958 172.946317,272.085444 173.666236,272.43 C174.537463,272.670242 175.47057,272.504928 176.206236,271.98 L193.556236,262.4 C194.581986,261.826871 195.453534,261.013654 196.096236,260.03 C196.729302,259.137332 197.077867,258.074208 197.096236,256.98 L197.096236,190.33 L211.826236,209.45 C213.081719,210.790465 215.131891,211.00358 216.636236,209.95 L224.076236,205.85 C226.287,204.499917 227.98075,202.446994 228.886236,200.02 L243.846236,164.52 L244.006236,226.13 L244.116236,231.4 C244.04551,232.173395 244.476048,232.905713 245.186236,233.22 C245.996126,233.494382 246.888438,233.364253 247.586236,232.87 L264.866236,222.87 C265.877126,222.273156 266.730817,221.443465 267.356236,220.45 C267.993141,219.551734 268.341896,218.481056 268.356236,217.38 L268.356236,111.77 C268.463505,110.961647 268.045753,110.174344 267.316236,109.81 C266.45613,109.516777 265.507535,109.654416 264.766236,110.18 Z"
            id="Metal³LogoPrimaryM"
            fill="#00D1BD"
          />
          <path
            d="M154.491236,0.779457742 C157.584115,0.160259997 160.739303,-0.0914836698 163.891236,0.0294577424 L163.891236,0.0294577424 L164.381236,0.0294577424 C167.432793,0.00565453027 170.452874,0.647208631 173.231236,1.90945774 L173.231236,1.90945774 L287.931236,56.7894577 C290.73214,58.2752261 293.244976,60.249597 295.351236,62.6194577 C296.402788,63.7814493 297.337302,65.044215 298.141236,66.3894577 C298.918689,67.7507215 299.545713,69.1925404 300.011236,70.6894577 L300.011236,70.6894577 L302.991236,83.2994577 L164.211236,155.387891 L26.2112358,80.2494577 L28.5612358,69.9494577 C29.9416317,63.8106551 34.110391,58.6690619 39.8312358,56.0494577 L39.8312358,56.0494577 Z M166.526236,52.9297497 C163.951899,52.9164246 161.4046,53.4552108 159.056236,54.51 L73.4562358,93.08 C72.8798678,93.3462362 72.5028176,93.9146463 72.4807296,94.549181 C72.4605153,95.1837158 72.7988766,95.7759729 73.3562358,96.08 L159.926236,142.36 L160.226236,142.51 L160.596236,142.69 L160.766236,142.77 L161.276236,142.98 L161.336236,142.98 C164.514572,144.18118 168.056274,143.955269 171.056236,142.36 L259.806236,95.21 C260.508743,94.8337917 260.934554,94.0892793 260.904297,93.2930194 C260.870683,92.4967595 260.386616,91.7887395 259.656236,91.47 L173.506236,54.37 C171.298801,53.429579 168.925624,52.9399837 166.526236,52.9297497 Z"
            id="Metal³LogoTopSquare"
            fill="#00E0C1"
          />
        </g>
      </g>
    </svg>
  )
}
