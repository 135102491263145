import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { listClusterAddons } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { metal3AddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import useListAction from 'core/hooks/useListAction'
import Metal3SignupPage from './Metal3SignupPage'
import Metal3DashboardPage from './Metal3DashboardPage'
import Progress from 'core/components/progress/Progress'
import { isEmpty } from 'ramda'
import { allKey } from 'app/constants'

export default function Overview() {
  const { loading: loadingClusterAddons, message, reload } = useListAction(listClusterAddons, {
    params: { clusterId: allKey },
    requiredParams: ['clusterId'],
  })
  const allMetal3Addons = useSelector(metal3AddonsSelector)

  const metal3HasNotBeenEnabled = useMemo(() => isEmpty(allMetal3Addons), [allMetal3Addons])

  return (
    <Progress message={message} loading={loadingClusterAddons}>
      {metal3HasNotBeenEnabled ? (
        <Metal3SignupPage />
      ) : (
        <Metal3DashboardPage metal3Addons={allMetal3Addons} reloadAddons={reload} />
      )}
    </Progress>
  )
}
