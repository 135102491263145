import React from 'react'
import ProductInfoPage from 'core/containers/ProductInfoPage'
import Text from 'core/elements/Text'
import CardButton from 'core/elements/button/CardButton'

import { Metal3Logo } from '../../logo'
import useToggler from 'core/hooks/useToggler'
import InstallMetal3Form from './InstallMetal3Form'
import { metal3InfoText, metal3EnableText } from '../helpers'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import { trackEvent } from 'utils/tracking'

export default function Metal3SignupPage() {
  const { history } = useReactRouter()
  const [modalOpen, toggleModalOpen] = useToggler(false)
  return (
    <>
      <InstallMetal3Form open={modalOpen} onClose={toggleModalOpen} />
      <ProductInfoPage
        title="Get Started with Metal³ for Bare Metal Management"
        footerTitle="Choose where to enable Metal³"
        icon={<Metal3Logo width="240" height="240" />}
        actions={[
          <CardButton
            key="metal3-choose-new"
            icon="plus"
            title="Create a New Cluster"
            message="Configure Add-ons to enable Metal³"
            onClick={() => {
              trackEvent('Clicked Create New Metal3 Cluster')
              history.push(routes.cluster.add.root.path({}))
            }}
          />,
          <CardButton
            onClick={() => {
              trackEvent('Clicked Add Metal3 Configuration to a Selected Cluster')
              toggleModalOpen()
            }}
            key="metal3-choose-existing"
            icon="check-double"
            title="Select an Existing Cluster"
            message="Add Metal³ configuration to a selected cluster"
          />,
        ]}
      >
        <Text variant="body1">{metal3InfoText}</Text>
        <Text variant="body1">{metal3EnableText}</Text>
      </ProductInfoPage>
    </>
  )
}
