import React, { useCallback, useState, useMemo } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Metal3ParamFields from 'app/plugins/infrastructure/components/clusters/cluster-addons/param-fields/Metal3ParamFields'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  createClusterAddon,
  listClusterAddons,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import MetalLbParamFields from 'app/plugins/infrastructure/components/clusters/cluster-addons/param-fields/MetalLbParamFields'
import { enableMetal3 } from '../../actions'
import { compareVersions } from 'k8s/util/helpers'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterAddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import { ClusterAddonType } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import DropdownField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'

const bareOsClustersOnly = (clusters) =>
  clusters.filter(
    (cluster) =>
      cluster.cloudProviderType === CloudProviders.BareOS &&
      compareVersions(cluster.kubeRoleVersion, '1.21') >= 0,
  )

export default function InstallMetal3Form({ open, onClose }) {
  const {
    update: enableAddon,
    updating: enablingAddon,
    error: errorEnablingAddon,
    reset,
  } = useUpdateAction(createClusterAddon)
  const [clusterId, setClusterId] = useState(null)
  const { loading } = useListAction(listClusterAddons, {
    params: { clusterId },
  })
  const enabledClusterAddons = useSelectorWithParams(clusterAddonsSelector, { clusterId })
  const showMetalLbField = useMemo(
    () => !enabledClusterAddons.find((addon) => addon.type === ClusterAddonType.MetalLb),
    [enabledClusterAddons],
  )

  const handleSubmit = useCallback(async (values) => {
    const success = await enableMetal3(values?.clusterId, values, enableAddon)
    if (success) {
      reset()
      onClose()
    }
  }, [])

  const handleClusterChange = useCallback(
    (setFieldValue) => (value) => {
      setFieldValue('clusterId')(value)
      setClusterId(value)
    },
    [],
  )

  return (
    <ModalForm
      open={open}
      title="Enable Metal3 on a cluster"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitTitle="Enable"
      error={errorEnablingAddon}
      submitting={enablingAddon}
    >
      {/* The state from ValidatedFrom is passed up here to this fn */}
      {({ setFieldValue, values }) => (
        <>
          <FormFieldSection title="Select a Cluster" step={1}>
            <DropdownField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Cluster"
              onChange={handleClusterChange(setFieldValue)}
              compact={false}
              filterFn={bareOsClustersOnly}
              required
            />
          </FormFieldSection>
          <FormFieldSection title="Configure Settings" step={2}>
            <Metal3ParamFields setFieldValue={setFieldValue} values={values} />
          </FormFieldSection>
          {showMetalLbField && (
            <FormFieldSection title="Configure Metal LB" step={3}>
              <MetalLbParamFields setFieldValue={setFieldValue} />
            </FormFieldSection>
          )}
        </>
      )}
    </ModalForm>
  )
}
