import { RootState } from 'app/store'
import { Selector } from '@reduxjs/toolkit'
import { complement, isNil, propSatisfies } from 'ramda'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { findClusterName } from 'k8s/util/helpers'
import { durationBetweenDates } from 'utils/misc'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { getBareMetalHostCounts } from './components/helpers'
import { IBareMetalHostSelector } from './model'
import { metal3AddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'

export const bareMetalHostsSelector: Selector<
  RootState,
  IBareMetalHostSelector[]
> = createSharedSelector(
  getDataSelector<DataKeys.BareMetalHosts>(DataKeys.BareMetalHosts, ['clusterId']),
  clustersSelector,
  (rawBareMetalHosts, clusters) => {
    return rawBareMetalHosts
      .map((bareMetalHost) => {
        return {
          ...bareMetalHost,
          clusterName: findClusterName(clusters, bareMetalHost.clusterId),
          age: durationBetweenDates({ labels: ['d'] })(bareMetalHost?.metadata?.creationTimestamp),
          isReady: bareMetalHost?.status?.provisioning?.state === 'ready',
          isProvisioned: bareMetalHost?.status?.provisioning?.state === 'provisioned',
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const metal3EnabledClustersSelector = createSharedSelector(
  bareMetalHostsSelector,
  clustersSelector,
  metal3AddonsSelector,
  (allBareMetalHosts, allClusters, allMetal3Addons) => {
    return allMetal3Addons.map((addon) => {
      const clusterId = addon.clusterId
      const bareMetalHosts = allBareMetalHosts.filter((host) => host.clusterId === clusterId)
      const { numHosts, numProvisionedHosts, numHostsReady } = getBareMetalHostCounts(
        bareMetalHosts,
      )
      const cluster = allClusters.find((cluster) => cluster.uuid === clusterId)
      return {
        ...cluster,
        numHosts,
        numProvisionedHosts,
        numHostsReady,
      }
    })
  },
)
