import React, { useMemo, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from 'core/elements/card/Card'
import Theme from 'core/themes/model'
import { metal3InfoText, metal3EnableText, getBareMetalHostCounts } from '../helpers'
import Text from 'core/elements/Text'
import { Metal3Logo } from '../../logo'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import AddonHealthStatusesCard from 'app/plugins/infrastructure/components/clusters/cluster-addons/cluster-details-page/AddonHealthStatusesCard'
import ListContainer from 'core/containers/ListContainer'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { listTablePrefs, TablePrefsParams, allKey } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import Badge, { BadgeVariant } from 'core/elements/badge/Badge'
import { useSelector } from 'react-redux'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import useListAction from 'core/hooks/useListAction'
import { listBareMetalHosts } from '../../actions'
import { bareMetalHostsSelector, metal3EnabledClustersSelector } from '../../selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { getClusterHealthStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import ExternalLink from 'core/components/ExternalLink'
import InferActionParams from 'core/actions/InferActionParams'

const metal3HelpLink =
  'https://platform9.com/docs/bare-metal/platform9-managed-baremetal-with-metal3'

type ModelDataKey = DataKeys.BareMetalHosts
type SelectorModel = ArrayElement<ReturnType<typeof metal3EnabledClustersSelector>>
type ActionParams = InferActionParams<typeof listBareMetalHosts>

const defaultParams: ActionParams = {
  clusterId: allKey,
}

const usePrefParams = createUsePrefParamsHook<ActionParams & TablePrefsParams>(
  'Metal3 Bare Metal Hosts',
  listTablePrefs,
)

const clusterHealthStatus = (healthStatus) => {
  let clusterStatus: BadgeVariant = 'unknown'
  const { status = '', label = 'N/A' } = getClusterHealthStatus(healthStatus)
  switch (status) {
    case 'ok':
      clusterStatus = 'success'
      break
    case 'pause':
      clusterStatus = 'warning'
      break
    case 'fail':
      clusterStatus = 'error'
      break
    case 'loading':
      clusterStatus = 'primary'
  }
  return { variant: clusterStatus, label }
}

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  { key: 'numHosts', label: 'Hosts' },
  {
    key: 'healthStatus',
    label: 'Cluster Health Status',
    accessor: (cluster) => cluster,
    CellComponent: createGridStatusCell({
      dataFn: clusterHealthStatus,
    }),
  },
  { key: 'numProvisionedHosts', label: 'Provisioned' },
  { key: 'numHostsReady', label: 'Ready' },
]

export default function Metal3DashboardPage({ metal3Addons, reloadAddons }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { loading, message, reload: reloadBareMetalHosts } = useListAction(listBareMetalHosts, {
    params,
    requiredParams: ['clusterId'],
  })
  const bareMetalHosts = useSelectorWithParams(bareMetalHostsSelector, {})
  const metal3EnabledClusters = useSelector(metal3EnabledClustersSelector)

  const { numHosts, numProvisionedHosts, numHostsReady } = useMemo(
    () => getBareMetalHostCounts(bareMetalHosts),
    [bareMetalHosts],
  )

  const reload = useCallback(() => {
    reloadAddons(true)
    reloadBareMetalHosts(true)
  }, [])

  useEffect(() => {
    reloadBareMetalHosts(true)
  }, [])

  return (
    <>
      <DocumentMeta title="Metal3 Overview" breadcrumbs />
      <HeaderTitlePortal>
        <Badge variant="secondary" text="Early Access" />
      </HeaderTitlePortal>
      <div className={classes.dashboardPage}>
        <div className={classes.sideContent}>
          <Card
            className={classes.card}
            title={
              <div className={classes.metal3CardTitle}>
                <Metal3Logo width="40" height="40" />
                <Text variant="subtitle2">Get Started with Bare Metal</Text>
              </div>
            }
          >
            <Text variant="body2">{metal3InfoText}</Text>
            <br />
            <Text variant="body2">{metal3EnableText}</Text>
          </Card>
          <Card className={classes.card}>
            <div className={classes.helpCardBody}>
              <FontAwesomeIcon className={classes.helpIcon}>question-circle</FontAwesomeIcon>
              <div>
                <Text variant="subtitle2">Need Help?</Text>
                <Text variant="body2">
                  Learn more on Bare Metal
                  <ExternalLink url={metal3HelpLink}>
                    <FontAwesomeIcon className={classes.linkIcon} size="md">
                      external-link
                    </FontAwesomeIcon>
                  </ExternalLink>
                </Text>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <div className={classes.headerCards}>
            <AddonHealthStatusesCard
              addons={metal3Addons}
              title={'Metal³ Add-ons Health Statuses'}
            />
            <Card className={classes.infoCard}>
              <Text className={classes.infoCardTitle} variant="caption1">
                Total Number of Hosts
              </Text>
              <Text variant="h1" className={classes.infoValue}>
                {numHosts}
              </Text>
            </Card>
            <Card className={classes.infoCard}>
              <Text className={classes.infoCardTitle} variant="caption1">
                Provisioned/Ready
              </Text>
              <Text variant="h1" className={classes.infoValue}>
                {`${numProvisionedHosts}/${numHostsReady}`}
              </Text>
            </Card>
          </div>
          <ListContainer<ModelDataKey, SelectorModel>
            label="Metal³ Core Sites"
            searchTargets={['name']}
            uniqueIdentifier="name"
            data={metal3EnabledClusters}
            columns={columns}
            getParamsUpdater={getParamsUpdater}
            showBreadcrumbs={false}
            onRefresh={reload}
            loading={loading}
            loadingMessage={message}
            {...pick(listTablePrefs, params)}
          />
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  dashboardPage: {
    display: 'grid',
    gridTemplateColumns: '368px 1fr',
    gridGap: theme.spacing(3),
  },
  sideContent: {
    display: 'grid',
    gridTemplateRows: 'max-content',
    gridGap: theme.spacing(3),
  },
  metal3CardTitle: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(3),
    padding: '16px 32px 8px 32px',
    alignItems: 'center',
    justifyContent: 'start',
  },
  card: {
    height: 'max-content',
  },
  helpCardBody: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'start',
  },
  helpIcon: {
    width: 32,
    height: 32,
    borderRadius: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: hexToRgbaCss(theme.components.button.primary.background, 0.1),
    color: theme.components.button.primary.background,
  },
  linkIcon: {
    marginLeft: theme.spacing(2),
  },
  headerCards: {
    display: 'grid',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(3),
    gridTemplateColumns: 'repeat(3, max-content)',
  },
  infoCard: {
    height: '116px',
    minWidth: '217px',
  },
  infoCardTitle: {
    color: theme.palette.grey['500'],
  },
  infoValue: {
    fontWeight: 'normal',
  },
}))
